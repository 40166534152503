(function() {
    angular.module('EntrakV5').controller('profileController', profileController);

    function profileController($scope, $rootScope, KEY, Service, Api, APIKEY, $state) {
        console.log('profileController');

        var caller = Api.createApiCaller();
        $scope.isEditingPwd = false;
        $scope.isSavingPwd = false;
        $scope.isEditingName = false;
        $scope.isSavingName = false;
        $scope.isSavingWsAction = false;
        $scope.pwdErr = '';
        $scope.nameErr = '';

        $scope.ws = null;
        $scope.wsName = '';
        $scope.delegations = null;//init to null, prevent user click b4 loaded

        $scope.editData = {};
        $scope.isSavingPreference = false;
        $scope.scheduleOffNotiOn = null;
        $scope.requestEmailOn = null;
        $scope.deviceOfflineEmailOn = null;

        $rootScope.loadingPage = 0;
        $scope.loadCurrentWs = function(){
            $scope.isSavingWsAction = true;
            $rootScope.loadingPage++;
            caller.call(Api.getCurrentWorkstation()).then(function(res){
                $scope.processCurrenWs(res);
                $scope.isSavingWsAction = false;
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.isSavingWsAction = false;
                $rootScope.generalErrorHandler();
            });
        }
        $scope.loadCurrentWs();

        $scope.processCurrenWs = function(res){
            if (res){
                $scope.ws = res;
                $scope.wsName = res.name;
                $scope.delegations = res.delegations;
            } else {
                $scope.ws = null;
                $scope.wsName = Service.translate("profile.noWorkstation");
            }            
        }

        $scope.editName = function(isEdit){
            if (!$rootScope.currentUser)
                return;

            $scope.isEditingName = isEdit;
            $scope.isSavingName = false;
            $scope.nameErr = '';
            $scope.editData.fName = $rootScope.currentUser.firstName;
            $scope.editData.lName = $rootScope.currentUser.lastName;
        }

        $scope.saveEditName = function(){
            $scope.nameErr = '';

            $scope.isSavingName = true;
            $rootScope.loadingPage++;
            caller.call(Api.updateProfile($rootScope.currentUser.id, $scope.editData.fName.trim(), $scope.editData.lName.trim())).then(function(res){
                $rootScope.currentUser.firstName = res.firstName;
                $rootScope.currentUser.lastName = res.lastName;
                $scope.editName(false);
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.nameErr = Service.translate('error.generalNameFail');
                $scope.isSavingName = false;
                $rootScope.loadingPage--;
            });
        }

        $scope.editPwd = function(isEdit){
            if (!$rootScope.currentUser)
                return;

            $scope.isSavingPwd = false;
            $scope.pwdErr = '';
            $scope.editData.oldPwd = '';
            $scope.editData.newPwd = '';
            $scope.editData.confirmPwd = '';

            $scope.showRules = false;
            $scope.isAllValid = false;
            $scope.rules = null;
            if (isEdit) {
              Service.formPost("auth/pwdpolicy", { email: $rootScope.currentUser.email })
                .then(Service.parseJson)
                .then(res => {
                  $scope.$apply(function(){
                    $scope.isEditingPwd = isEdit;
                    $scope.showRules = true;
                    $scope.rules = [];
                    if (res.policies) {
                      res.policies.forEach(policy => {
                        if (policy.required) {
                          if (policy.name === "length") {
                            let lbl = policy.max && policy.max >= policy.min ? Service.translate("label.pwdMinMax", policy) : Service.translate("label.pwdMin", policy);
                            $scope.rules.push({ lbl, isValid: false, exp: new RegExp(policy.rule) });
                          } else if (policy.name === "at least one number") {
                            $scope.rules.push({ lbl: Service.translate("label.pwdNumber"), isValid: false, exp: new RegExp(policy.rule) });
                          } else if (policy.name === "at least one upper case") {
                            $scope.rules.push({ lbl: Service.translate("label.pwdUpperCase"), isValid: false, exp: new RegExp(policy.rule) });
                          } else if (policy.name === "at least one lower case") {
                            $scope.rules.push({ lbl: Service.translate("label.pwdLowerCase"), isValid: false, exp: new RegExp(policy.rule) });
                          } else if (policy.name === "at least one special char") {
                            $scope.rules.push({ lbl: Service.translate("label.pwdSpecialChar"), isValid: false, exp: new RegExp(policy.rule) });
                          }
                        }
                      });
                    }
                  });
                })
                .catch(err => {
                  $scope.$apply(function(){
                    console.error("err", err);
                    $scope.errMsg = Service.translate("error.generalResetPwdFail");
                  });
                });
            } else {
              $scope.isEditingPwd = isEdit;
            }
        }
        $scope.handleValidation = function(){
          $scope.rules.forEach(r => {
            r.isValid = r.exp.test($scope.editData.newPwd);
          });
          $scope.isAllValid = $scope.rules.find(r => !r.isValid) == null;
        }
        $scope.saveEditPwd = function(){
            $scope.pwdErr = '';
            $scope.isSavingPwd = true;
            $rootScope.loadingPage++;
            Service.formPost("auth/changepwd", {old_password: $scope.editData.oldPwd, new_password: $scope.editData.newPwd })
              .then(Service.parseJson)
              .then(r => {
                $scope.$apply(function(){
                  if (r.errno) {
                    if (r.errno == 504) {
                      $scope.pwdErr = Service.translate('error.checkPwd');
                    } else {
                      $scope.pwdErr = Service.translate('error.generalResetPwdFail');
                    }
                    console.log("change pwd error", r.errno, r.errmsg);
                  } else {
                    $scope.editPwd(false);
                    Api.logout();
                  }
                  $rootScope.loadingPage--;
                  $scope.isSavingPwd = false;
                });
              })
              .catch(e => {
                $scope.$apply(function(){
                  $scope.pwdErr = Service.translate('error.generalResetPwdFail');
                  $scope.isSavingPwd = false;
                  $rootScope.loadingPage--;
                });
              });
        }

        $scope.togglePreference = function(){
            $scope.isSavingPreference = true;
            $rootScope.loadingPage++;
            caller.call(Api.updateUserPreference($scope.scheduleOffNotiOn, $scope.requestEmailOn, $scope.deviceOfflineEmailOn)).then(function(res){
                $rootScope.currentUser.notificationPreference = res;
                $scope.isSavingPreference = false;
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.isSavingPreference = false;
                $rootScope.loadingPage--;
            });
        }

        $scope.back = function(){
            $state.go($rootScope.previousState ? $rootScope.previousState : 'dashboard', $rootScope.previousParams);
        }
    
        $scope.releaseWorkstation = function(){
            $scope.isSavingWsAction = true;
            $rootScope.loadingPage++;
            //api have bug, if user assigned ws and then checkin another hotdesk, then call releaseWs, will release the assigned ws instead of hotdesk
            //so need to call closeWs if current ws is hotdesk, it will auto release hotdesk
            caller.call($scope.ws.isHotDesk ? Api.turnOffWorkstation($scope.ws.id) : Api.releaseWorkstation()).then(function(res){
                $scope.loadCurrentWs();
                $scope.isSavingWsAction = false;
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.isSavingWsAction = false;
                $rootScope.generalErrorHandler();
            });
        }

        $scope.showDelegatePopup = function(){
            $scope.isSavingWsAction = true;
            $rootScope.loadingPage++;
            caller.call(Api.getProfiles($rootScope.currentUser.tenant.id, 0)).then(function(res){
                res.sort(Service.getSorter("email"));
                $rootScope.showDelegateWsWin($scope.ws.id, $scope.wsName, res, $scope.delegations.map(function(d){
                    return d.userId;
                }), function(delegateList){
                    $scope.delegations = delegateList;
                });
                $scope.isSavingWsAction = false;
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.isSavingWsAction = false;
                $rootScope.generalErrorHandler();
            });
        }

        $scope.showSelectWsPopup = function(){
            $scope.isSavingWsAction = true;
            $rootScope.loadingPage++;
            caller.call(Api.getAvailableWorkstations()).then(function(res){
                if ($scope.ws){//have currentWs, only allow to choose hotdesk
                    res = res.filter(function(ws){
                        return ws.isHotDesk;
                    });
                }
                $rootScope.showSelectWsWin(res, $scope.ws, Service.translate($scope.ws ? "dashboard.panel.switchWsReminder" : "dashboard.panel.selectWsReminder"), function(ws){
                    $scope.processCurrenWs(ws);
                });
                $scope.isSavingWsAction = false;
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.isSavingWsAction = false;
                $rootScope.generalErrorHandler();
            });
        }

        $scope.pwdExpiredWinOpt = {
            width: "500px",
            title: Service.translate("profile.pwdExpired"),
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            actions: ["Close"]
        }
        $rootScope.getTenantId().then(function(tenantId){
            // if ($rootScope.needChangePwd($rootScope.currentUser)){
            //     $scope.pwdExpiredWin.center().open();
            //     $scope.editPwd(true);
            // }
            $scope.scheduleOffNotiOn = $rootScope.currentUser.notificationPreference.workstationCloseAlert;
            $scope.requestEmailOn = $rootScope.currentUser.notificationPreference.frequentRequestAdminAlert;
            $scope.deviceOfflineEmailOn = $rootScope.currentUser.notificationPreference.deviceOfflineAlert;
        });

        $scope.$on('$destroy', function() {
            console.log("profileController destroy");
            caller.cancel();
        });
    }
})();
